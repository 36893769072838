//_base.scss means it is a partial file

// This is the basic definations of our entire project
//so, this treats as a base file

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  /* boders and padding are no longer added to total height and width  */
}
html {
  //This defines what 1rem is
  font-size: 62.5%;
  /* 100% is 16px  */
  /* 62.5% is 10px which is easy to calculate */
  /* 1 rem=10px */
}

body {
  box-sizing: border-box;
  color: black !important;
}
