.table {
  overflow-x: scroll;
  table {
    width: 100%;
    border-spacing: 0;
    thead {
      th {
        font-weight: 600;
        color: #212b36;
        border-bottom: 0.1rem solid #c4cdd5;
        border-top: 0;
        padding: 1.5rem 1rem;
        white-space: nowrap;
        text-align: left;
        transition: background-color 0.2s ease-in-out;
        vertical-align: top;
      }
    }
    tbody {
      tr {
        td {
          padding: 0.8rem;
          border-bottom: 0.1rem solid #f4f6f8;
          white-space: nowrap;
          text-align: left;
          transition: background-color 0.2s ease-in-out;
          vertical-align: top;
        }
      }
    }
  }
}
