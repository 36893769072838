

.privacy > .heading {

    background-color: #fafaf9;
    padding-left: 10%;
    padding-right: 2px;
    height: 120px;
    font-size: 30px;
    padding-top: 45px;
    padding-bottom: 45px;
}

.privacy > .body {

    margin-top: 30px;
    margin-bottom: 100px;
    padding-left: 10%;
}

ul, ol {
    position: relative;
    margin-left: 30px;
}

.privacy > .body > p {
 
   font-size: 15px;
}



