//import main scc file to use variable
@import "../Sass/main.scss";

.main_section {
  padding: 0 3.6rem;
  margin-top: 2rem;
}

.navbar {
  flex: 1 1 auto;
  overflow: hidden;
  box-sizing: border-box;
  background-color: white;

  .ul_list {
    display: flex;
    margin: 0;
    padding: 0 3.6rem;
    border-bottom: 0.1rem solid #dfe3e8;
    list-style: none;

    .li_list {
      display: flex;
      margin: 0;
      padding: 0;
      padding-right: 2rem;

      .link {
        color: inherit;
        appearance: none;
        margin: 0;
        padding: 1rem 0;
        background: none;
        border: none;
        font-size: inherit;
        line-height: inherit;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.5rem;
        text-transform: none;
        letter-spacing: normal;
        color: #637381;
        position: relative;
        justify-content: center;
        width: 100%;
        min-width: 100%;
        margin-top: 1px;
        margin-bottom: -1px;
        outline: none;
        text-align: center;
        white-space: nowrap;
        text-decoration: none;
        cursor: pointer;
        border-bottom: 0.3rem solid transparent;
        &:hover {
          color: $color-black;
          border-color: $color-grey-dark;
          box-sizing: border-box;
        }
      }
    }
  }
}

.selected {
  border-bottom: 0.3rem solid $color-blue !important;
  box-sizing: border-box;
}


.Polaris-Tooltip__Content {
  max-width: 28rem !important;
}
.Polaris-Tooltip__Wrapper {
  padding: 5px;
}
