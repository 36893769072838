.heading-primary {
  color: $color-black;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  backface-visibility: hidden;
}

.heading-secondary {
  color: $color-black;
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0;
  backface-visibility: hidden;
}

.heading-tertiary {
  color: $color-black;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  backface-visibility: hidden;
}
.paragraph {
  color: $color-black;
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
  backface-visibility: hidden;
}
.paragraph_grey {
  color: #00000080;
  font-size: 1.4rem;
  font-weight: 400;
}
.paragraph_black {
  color: $color-black;
  font-size: 1.4rem;
  font-weight: 600;
}
.link {
  color: $link;
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
  backface-visibility: hidden;
  text-decoration: none;
}
.link_text {
  color: $color-link;
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
  backface-visibility: hidden;
}
