//import main scc file to use variable
@import "../../Sass/main.scss";
.dashboard {
  height: 70vh;
  .card_wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .card {
      width: 32%;
      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 20px;
      }

      .heading {
        text-transform: uppercase;
        text-align: left;
        color: rgba(0, 0, 0, 0.4);
        font-size: 1.1rem;
        font-weight: 400;
        margin-bottom: 1rem;
      }
      .order_count {
        text-align: left;
        font-size: 1.3rem;
        font-weight: 500;
      }
    }
  }
}
