// utilities classes have only one simple goal
//i.e, to make a text center or add padding and margin

.u-center-text {
  text-align: center;
}

//Margin
.u-margin-bottom-big {
  margin-bottom: 3rem;
}
.u-margin-bottom-medium {
  margin-bottom: 2.5rem !important;
}
.u-margin-bottom-small {
  margin-bottom: 1.5rem;
}
.u-margin-bottom-20 {
  margin-bottom: 2rem;
}
.u-margin-bottom-5 {
  margin-bottom: 0.5rem;
}

.u-margin-top-big {
  margin-top: 8rem;
}
.u-margin-top-20 {
  margin-top: 2rem;
}
.u-margin-top-32 {
  margin-top: 2rem;
}
.u-margin-top-medium {
  margin-top: 2.5rem;
}
.u-margin-top-small {
  margin-top: 1.5rem;
}

.u-margin-left-10 {
  margin-left: 1rem;
}
//Padding
.u-padding {
  padding: 1rem;
}
.u-padding-left-right {
  padding: 0 1rem 0 1rem;
}
.u-padding-bottom-big {
  padding-bottom: 8rem;
}
.u-padding-bottom-medium {
  padding-bottom: 4rem !important;
}

.u-padding-bottom-small {
  padding-bottom: 1.5rem;
}

.u-padding-top-big {
  padding-top: 8rem;
}
.u-padding-top-medium {
  padding-top: 4rem;
}
.u-padding-top-small {
  padding-top: 1.5rem;
}
.u-padding-bottom-20 {
  padding-bottom: 2rem;
}

//
.u-text-center {
  text-align: center;
}
.u-text-uppercase {
  text-transform: uppercase;
}

//Float
.float_right {
  float: right;
}

//Active class
.active {
  background-color: $color-sky;
  color: $color-black;
}

//follow up status
.status {
  border-radius: 1.5rem;
  padding: 0.2rem 0.9rem;
}
.zero-followup {
  background-color: $zero-followup;
}
.one-followup {
  background-color: $one-followup;
}

.two-followup {
  background-color: $two-followup;
}

//COMPONENTS

//polaris button
.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0.7rem 1.6rem;
  background: linear-gradient(to bottom, white, #f9fafb);
  border: 0.1rem solid #c4cdd5;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 3px;
  line-height: 1;
  color: #212b36;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  transition-property: background, border, box-shadow;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
}

.select_button {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 16px;
  text-transform: none;
  letter-spacing: normal;
  position: relative;
  height: 100%;
  margin: 0;
  /* padding: 0.7rem 1.6rem; */
  background: linear-gradient(180deg, #fff, #f9fafb);
  border: 0.1rem solid #c4cdd5;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 3px;
  color: #212b36;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

.Polaris-TextField {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-transform: none;
  letter-spacing: normal;
  position: relative;
  display: flex;
  align-items: center;
  color: #919eab;
  cursor: text;
}

.Polaris-TextField__Prefix {
  position: relative;
  z-index: 20;
  flex: 0 0 auto;
  color: currentColor;
  pointer-events: none;
  margin: 0 0.25em 0 1.2rem;
}
.Polaris-Icon {
  display: block;
  height: 2rem;
  width: 2rem;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

.Polaris-Icon__Svg {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.Polaris-TextField__Input {
  font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    text-transform: none;
    letter-spacing: normal;
    position: relative;
    z-index: 20;
    display: block;
    flex: 1 1;
    width: 100%;
    min-width: 0;
    min-height: 3.6rem;
    margin: 0;
    padding: .5rem 1.2rem;
    background: none;
    border: .1rem solid transparent;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.Polaris-TextField__Backdrop {
      position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border: .1rem solid #c4cdd5;
    border-radius: 3px;
    box-shadow: inset 0 1px 0 0 rgba(99,115,129,.05);
    pointer-events: none;

  &:after {
    content: "";
    position: absolute;
    top: -.2rem;
    right: -.2rem;
    bottom: -.2rem;
    left: -.2rem;
    border: .2rem solid #5c6ac4;
    border-radius: inherit;
    opacity: 0;
    transition: opacity .2s cubic-bezier(.64,0,.35,1);
    pointer-events: none;
  }
}