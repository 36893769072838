//Declaring saas variables

// SKY
$color-sky: #edf3f8;
$color-sky-light: #f4f6f8;

//COLORS
$color-white: #fff;
$color-black: #212b36;

//GREY
$color-grey-lighter: #f9fafb;
$color--grey-light: #f4f6f8;
$color-grey-sky: #dfe3e8;
$color-grey-dark: #c4cdd5;

//BLACK
$color-black-light: #00000080;
$color-black-link: #000000a3;

//LINK COLOR
$color-link: #47c1bf;

//BlUE
$color-blue: #5c6ac4;
$color-green: #50b83c;
$color-red: #de3618;

//Box shadow
$box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
  0 1px 3px 0 rgba(63, 63, 68, 0.15);

//Border
$border: 0.1rem solid #dfe3e8;

//Right arrow fill color
$right-arrow: #00000057;

//Link color
$link: #007ace;
$color-link: #000000b8;

//Status color
$zero-followup: #b4e0fa;
$one-followup: #b4e0fa;
$converted-color: #bbe5b3;
$two-followup: #ffea8a;
$three-followup: #ffc48b;
