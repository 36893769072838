//import main scc file to use variable
@import "../../Sass/main.scss";

.order {
  .Polaris-Card {
    background-color: white;
  }
  .content {
    padding: 2rem;
  }
  .table_filter {
    // padding-bottom: 2rem;
    .filter_wrapper {
      display: flex;
      justify-content: space-between;
      .search_filter {
        width: 60%;
        .button_group {
          height: 100%;
          display: flex;
          flex-wrap: nowrap;
          margin-top: 0;
          margin-left: 0;
          .group_item {
            position: relative;
            z-index: 10;
            margin-top: 0;
            margin-left: 0;
            width: 100%;
            input {
              &::placeholder {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
      .export_filter {
        width: 38%;
        display: flex;
        .btn {
          height: 100%;
        }
        .Polaris-Button {
          text-decoration: none;
          &:hover {
            color: #000;
          }
        }
        .shipOrder {
          margin-left: 2.7rem;
          .Polaris-Button {
            min-width: 100px;
          }
        }
      }
    }
  }

  .table {
    .Polaris-TextFiled .Polaris-TextField__Input{
      
    }
    .Polaris-Button__Content {
      // font-size: 1.5rem;
      // line-height: 1.6rem;
      font-weight: 400;
      text-transform: none;
      letter-spacing: normal;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 1px;
      min-height: 1px;
      img {
        height: 15px;
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: right;
    margin-top: 1rem;
  }
}

//Invoice Design

.invoice {
  text-align: center;
  background: #ffffff;
  margin-top: 30px;
  width: 80%;
  table {
    width: 80%;
    border-collapse: collapse;
    border: 0;
    font-size: 8px;
  }
  .table_one {
    width: 80%;
    border-collapse: collapse;
    border: 0;
    th {
      border: 1px solid black;
      text-align: left;
    }
    td {
      border: 1px solid black;
      text-align: left;
    }
  }
  .table_two {
    border-collapse: collapse;
    th {
      border: 1px solid black;
      text-align: left;
    }
    td {
      border: 1px solid black;
      text-align: left;
    }
  }

  .table_three {
    margin-top: 30px;
    padding: 0 20px;
    th {
    }
    td {
      text-align: left;
    }
  }

  .hereby_text {
    text-align: left;
    margin: 20px 0;
  }
}

.invoice2 {
  .heading {
    text-align: center;
  }
  .flex_wrap_one {
    display: flex;
    .left {
      width: 40%;
    }
    .right {
      width: 40%;
    }
  }

  #myCanvas {
    display: none;
  }
}
