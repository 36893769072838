//import main scc file to use variable
@import "../../Sass/main.scss";

.settings {
  .heading_wrapper {
    width: 95%;
    margin: 30px auto;
    @media (max-width: 990px) {
      width: 98%;
    }
  }

  .form_wrapper {
    display: flex;
    justify-content: space-between;
    width: 95%;
    @media (max-width: 990px) {
      width: 98%;
    }
    margin: 30px auto;
    flex-direction: column;
    .left,
    .right {
      display: flex;
      flex-wrap: wrap;
    }
    .meis-wrapper {
      @media (min-width: 767px) {
        height: 30px;
        margin-bottom: 5px;
      }
    }
    .sub-heading {
      flex: 0 0 auto;
      margin: 0 10px 20px;
      font-size: 20px;
      border-bottom: outset;
      padding: 0 0 5px;
      width: 98%;
      @media (max-width: 767px) {
        width: 100%;
        margin: 0 0 20px;
      }
    }
    .text_field {
      margin: 0.5rem 0rem;
      flex: 0 0 50%;
      padding: 0 10px;
      @media (max-width: 767px) {
        flex: 0 0 100%;
        padding: 0;
      }
      label {
        display: block;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .input_field {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.6rem;
        text-transform: none;
        letter-spacing: normal;
        width: 100%;
        min-width: 0;
        min-height: 2.6rem;
        margin: 0;
        padding: 0.5rem 1rem;
        background: #fff;
        border: 0.1rem solid #c4cdd5;
        border-radius: 3px;
        box-shadow: inset 0 1px 0 0 rgba(99, 115, 129, 0.05);
        outline: none;
        &:focus {
          border: 0.2rem solid #5c6ac4;
        }
      }
      .submit {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-height: 2.6rem;
        min-width: 2.6rem;
        margin: 0;
        font-size: 1rem;
        padding: 0.5rem 1rem;
        // background: linear-gradient(180deg, #fff, #f9fafb);
        background: linear-gradient(180deg, #6371c7, #5563c1);
        border: 0.1rem solid #3f4eae;
        box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
        border-radius: 3px;
        line-height: 1;
        color: #fff;
        text-align: center;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        text-decoration: none;
        transition-property: background, border, box-shadow;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
      }
    }
    .btn_field {
      text-align: right;
      margin-top: 2rem;
      flex: 0 0 100%;
    }
  }
  .Polaris-VerticalStack--listReset {
    --pc-vertical-stack-order: row !important;
    li {
      margin-right: 30px;
    }
  }

  .Polaris-ChoiceList__Choices {
    display: flex;
    li {
      margin-right: 15px;
    }
  }
  [data-feedback] {
    margin-bottom: 10px;
  }

  [data-feedback].error {
    color: red;
  }
  /* Chrome, Safari, Edge, Opera */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
